var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-autocomplete", {
    staticClass: "autocomplete-box",
    attrs: {
      size: _vm.size,
      value: _vm.value,
      "fetch-suggestions": _vm.fetchSearch,
      placeholder: _vm.placeholder,
      "trigger-on-focus": _vm.focus,
      "value-key": _vm.valueKey,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("change", $event)
      },
      select: _vm.handleSelect,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }